import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star";
import Tabs from "../../components/tabs";
import DownloadButton from "../../components/downloadButton";

const FieldTripToScience = () => {

    const largeFiles = ['FColorAGNLG.pdf','FColorBNSLG.pdf', 'FColorBubbleLG.pdf',  'FColorSkymapLG.pdf', 'FColorSNRLG.pdf', 'FLinkDotstAGNLG.pdf', 'FLinkDotstBNSLG.pdf', 'FLinkDotstBubbleLG.pdf', 'FLinkDotstMapLG.pdf', 'FLinkDotstSatLG.pdf', 'FLinkDotstSNRLG.pdf', 'FmatchBkBgdLG.pdf', 'FmatchGameLG.pdf', 'MapPuzzleLG.pdf'];
    const smallFiles = ['FColorAGNReg.pdf', 'FColorBNSReg.pdf', 'FColorBubbleReg.pdf', 'FColorSaReg.pdf', 'FColorSkymapReg.pdf', 'FColorSNRReg.pdf', 'FLinkDotsBNSreg.pdf', 'FLinkDotsBubbleReg.pdf', 'FLinkDotsMapReg.pdf', 'FLinkDotsSatReg.pdf', 'FLinkDotsSNRreg.pdf', 'FmatchBkBgdReg.pdf', 'FmatchGameReg.pdf', 'MapPuzzleReg.pdf'];
    const primaryPDF ='MultiDiverActi082426Pt.pdf';

    const buttonStyles = {
        padding: '1%'
    }

    const resourceTabs = [
        {
            tabName:'Large PDF Files (11"x17")',
            content:
                largeFiles.map( (r,index) =>
                    <div style={buttonStyles} key={index}>
                        <DownloadButton text={r} link={`/${r}`}/>
                    </div>    
                )
        },
        {
            tabName:'Regular PDF Files (8.5"x11")',
            content:
                smallFiles.map( (r,index) =>
                    <div style={buttonStyles} key={index}>
                        <DownloadButton text={r} link={`/${r}`}/>
                    </div>    
                )
        },
    ]

    return (
        <Layout>
            <div style={{display:'flex', flexDirection:'column'}}>
                <h1>Multi-Diverse Activities:<br/> Hands-On Sensory Field Trip To Space!</h1>
                <Star />   

                <p>This booklet includes a variety of Fermi science-themed activities inspired by the “stained glass” illustrations. The activities are designed to aid in the development of fine motor skills and the stimulation of various senses. Anyone can enjoy these fun activities while learning about the exciting Fermi mission and its study of the gamma-ray sky!</p>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                    <DownloadButton text='Activities Booklet' link={`/${primaryPDF}`}/>
                    <DownloadButton text='Material List' link={`/Multi-Diverse-Activities Materials List.pdf`}/>
                </div>
                <br/>

                <h2>Additional Resources</h2>
                <div className="media-items-container">
                    <Tabs tabContents={resourceTabs}/>
                </div>
            </div>
        </Layout>
    );
}

export default FieldTripToScience;